import React from "react"
import Layout from "../components/layout"

export default ({location}) => {
  const meta = {
    title: "Contact",
    description: "Ways you can get in touch with us",
    publicationDate: `2020-02-01`,
    lastUpdated: `2020-02-01`,
  }
  return (
    <Layout 
      title={meta.title}
      description={meta.description}
      pathname={location.pathname}
      publicationDate={meta.publicationDate}
      lastUpdated={meta.lastUpdated}>
      <div className="px-12 py-10 md:py-8">
        <div className="md:max-w-6xl md:mx-auto">
          <div className="pb-4">
            <h3>Contact</h3>
            <h2>{meta.description}</h2>
          </div>
          <div className="px-4">
            <section>
              <p>While we're getting Speaking of Products started, the easiest way for you to contact us is via our Twitter account.
              </p>
              <p><a href="https://twitter.com/SpeakOfProducts">twitter.com/speakofproducts</a>
              </p>
              <hr className="my-8" />
              <p>You can also connect with Dan on the web via:              
              </p>
              <p><a href="https://twitter.com/mrdanmiller">twitter.com/mrdanmiller</a>
              </p>
              <p><a href="https://www.linkedin.com/in/mrdanmiller/">linkedin.com/in/mrdanmiller</a>
              </p>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  )
}